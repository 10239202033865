html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  height: 100%;
  font-size: 16px;
  min-width: 300px;
  overflow-x: hidden;
}
#__next {
  height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.grecaptcha-badge {
  visibility: hidden;
}
